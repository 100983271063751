import React from "react"
import { Map } from "@components"

import "./map-container.scss"

export default function MapContainer(props) {
  const { image } = props.data

  const location = {
    address: "Kvarnbergsgatan 2 411 05 Göteborg",
    lat: 57.70832,
    lng: 11.96289,
  }

  return (
    <div className="map-container">
      <Map location={location} zoomLevel={16} />
    </div>
  )
}

export const query = graphql`
  fragment MapContainer on StrapiPageContent {
    image {
      childImageSharp {
        fluid(maxWidth: 660) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
