import React from "react"
import Img from "gatsby-image"
import "./image-link.scss"
import { Button } from "@components"

export default function ImageLink(props) {
  const {
    prefix,
    header,
    width,
    linkText,
    linkUrl,
    image,
    imageMobile,
    textColor,
    textColorMobile,
    addImageShadow,
  } = props.data

  const shadow = addImageShadow ? " -shadow" : " "

  return (
    <div className={"image-link -" + width}>
      <div className="image">
        <Img
          fluid={image.childImageSharp.fluid}
          style={{ maxHeight: "500px", minHeight: "375px" }}
        />
      </div>
      <div className={"image-mobile" + shadow}>
        <a href={linkUrl}>
          <Img
            fluid={imageMobile.childImageSharp.fluid}
            style={{ maxHeight: "375px", minHeight: "375px" }}
          />
        </a>
      </div>
      <div className={"text -" + textColor + " -mobile-" + textColorMobile}>
        <span
          className={"prefix -" + textColor + " -mobile-" + textColorMobile}
        >
          {prefix}
        </span>
        <h2 className="header large bold">{header}</h2>
        <Button text={linkText} url={linkUrl} />
      </div>
    </div>
  )
}
