import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import "./image-hero.scss"

export default function ImageHero(props) {
  console.log("props: ", props)
  const {
    prefix,
    header,
    heroImage,
    heroImageMobile,
    titlePosition,
  } = props.data

  return (
    <section className="image-hero">
      <div className="image">
        <Img
          fluid={heroImage.childImageSharp.fluid}
          style={{ maxHeight: "100%", minHeight: "100%" }}
        />
      </div>
      <div className="image-mobile">
        <Img
          fluid={heroImageMobile.childImageSharp.fluid}
          style={{ maxHeight: "100%", minHeight: "100%" }}
        />
      </div>

      <div className={"title -" + titlePosition}>
        <hr className={"borderdetail -upper -" + titlePosition} />
        <div>
          {prefix && <span className="titleprefix prefix">{prefix}</span>}
        </div>
        <h1 className="titleheader">{header}</h1>
        <hr className="borderdetail -lower" />
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HeroContent on StrapiPageContent {
    prefix
    header
    titlePosition
    heroImage {
      childImageSharp {
        fluid(maxHeight: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroImageMobile {
      childImageSharp {
        fluid(maxHeight: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
