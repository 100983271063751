import React from "react"

import "./icon-and-text.scss"

export default function IconAndTextItem(props) {
  const { header, icon, text } = props.data

  return (
    <div className="icon-and-text">
      <div className="icon">
        <img src={icon.publicURL} alt="icon" />
      </div>
      <div className="text">
        <h4>{header}</h4>
        <p>{text}</p>
      </div>
    </div>
  )
}
