import React from "react";
import './button.scss';
import '@css/icons/caret.scss';

export default function Button(props) {
  const { text, url } = props;

  return (
      <a href={url} className="button">
        {text} <i className="caret" />
      </a>
  )
}