import React from "react"
import { graphql } from "gatsby"
import ImageAndTextItem from "./image-and-text-item"
import "./image-and-text.scss"

export default function ImageAndTextSection(props) {
  const { imageAndTextComponents } = props.data

  return (
    <section className="image-and-text-section">
      {imageAndTextComponents.map(c => {
        return <ImageAndTextItem key={c.id} data={c} />
      })}
    </section>
  )
}

export const query = graphql`
  fragment ImageAndTextSectionContent on StrapiPageContent {
    imageAndTextComponents {
      image {
        childImageSharp {
          fluid(maxWidth: 660) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageMobile {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      prefix
      header
      text
      imagePosition
      id
    }
  }
`
