import React from "react"
import "./footer.scss"
import logo from "./y_logo_red.png"
import { graphql, useStaticQuery } from "gatsby"

export default function Footer() {
  const data = useStaticQuery(query)
  console.log(data)
  return (
    <footer className="footer">
      <h2 className="title">{data.strapiFooter.header}</h2>
      <a className="email" href={`mailto:${data.strapiFooter.email}`}>
        {data.strapiFooter.email}
      </a>
      <p className="address">{data.strapiFooter.address}</p>
      <a className="logo" href="/">
        <img src={logo} alt="yalta logotype" />
      </a>
    </footer>
  )
}

const query = graphql`
  query {
    strapiFooter {
      header
      email
      address
    }
  }
`
