import React from "react";
import { graphql } from 'gatsby';
import './image-section.scss';
import Img from 'gatsby-image';

export default function ImageSection(props) {
  const { images } = props.data;

  return (
    <section className="image-section">
      {images.map((imageItem) => {
        return (
          <div key={imageItem.id} className={"image -" + imageItem.width}>
            <Img fluid={imageItem.image.childImageSharp.fluid} />
          </div>
        );

        }) 
      }
    </section>
  )
}

export const query = graphql`
  fragment ImageSectionContent on StrapiPageContent {
    images {
      width
      image {
        childImageSharp {
          fluid(maxWidth: 660) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;