import React from "react"
import { graphql } from "gatsby"
import IconImageAndTextItem from "./icon-image-and-text-item"
import "./icon-image-and-text.scss"

export default function IconImageAndTextSection(props) {
  const { header, header2, iconImageAndTextComponents } = props.data

  return (
    <section className="icon-image-and-text-section">
      <div className="center-col">
        <div className="header-container">
          <h2 className="header centered">{header}</h2>
          <h2 className="header centered">{header2}</h2>
        </div>
        <div className="container">
          {iconImageAndTextComponents.map(c => {
            return <IconImageAndTextItem key={c.id} data={c} />
          })}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment IconImageAndTextSectionContent on StrapiPageContent {
    header
    header2
    iconImageAndTextComponents {
      icon_image {
        publicURL
      }
      header
      text
      id
    }
  }
`
