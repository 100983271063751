import React from "react";
import Img from 'gatsby-image';
import './contact.scss';

export default function Contact(props) {
  const { name, title, phone, email, image } = props.data;

  return (
    <div className="contact">
      <Img fluid={image.childImageSharp.fluid} />
      <span className="name">{name}</span>
      <span className="title">{title}</span>
      <span className="email">{email}</span>
      <span className="phone">{phone}</span>
    </div>
  )
}