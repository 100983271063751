import React from "react"
import GoogleMapReact from "google-map-react"
import mapsMarker from "./y_logo_red.png"
import "./map.scss"

const GOOGLE_MAPS_API_KEY = "AIzaSyDo9-cu_czvfhroU992oOKeeAsrjZKCwXs"

// TODO add location pin icon
const LocationPin = ({ text }) => (
  <div className="pin">
    <img
      src={mapsMarker}
      className="yalta-map-marker"
      alt="google maps marker"
    />
    <p className="pin-text">{text}</p>
  </div>
)

// TODO Restrict API key
const Map = ({ location, zoomLevel }) => (
  <div className="map">
    <div className="google-map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
        defaultCenter={location}
        defaultZoom={zoomLevel}
      >
        <LocationPin
          lat={location.lat}
          lng={location.lng}
          text={location.address}
        />
      </GoogleMapReact>
    </div>
  </div>
)

export default Map
