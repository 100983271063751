import React from "react"
import Img from "gatsby-image"
import "./image-and-text.scss"

export default function ImageAndTextSection(props) {
  const {
    elementId,
    prefix,
    header,
    image,
    imageMobile,
    text,
    imagePosition,
  } = props.data

  return (
    <div
      id={elementId}
      className={"image-and-text-item -image" + imagePosition}
    >
      <div className="image">
        <Img fluid={image.childImageSharp.fluid} />
      </div>
      <div className="image-mobile">
        <Img fluid={imageMobile.childImageSharp.fluid} />
      </div>
      <div className="text">
        <span className="prefix small">{prefix}</span>
        <h2 className="bold large no-margin">{header}</h2>
        <p>{text}</p>
      </div>
    </div>
  )
}
