import React from "react";
import { graphql } from 'gatsby';
import IconAndTextItem from './icon-and-text-item';
import './icon-and-text.scss';

export default function IconAndTextSection(props) {
  const { header, iconAndTextComponents } = props.data;

  return (
    <section className="icon-and-text-section">
      <div className="center-col">
        <h2 className="header centered">{header}</h2>
        {iconAndTextComponents.map((c) => { return <IconAndTextItem key={c.id} data={c} /> }) }
      </div>
    </section>
  )
}

export const query = graphql`
  fragment IconAndTextSectionContent on StrapiPageContent {
    header
    iconAndTextComponents {
        icon {
            publicURL
        }
        header
        text
        id
    }
  }
`;