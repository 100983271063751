import React from "react"
import { graphql } from "gatsby"
import ImageLink from "./image-link"
import "./image-link.scss"

export default function ImageLinkSection(props) {
  const { imageLinks } = props.data

  return (
    <section className="image-link-section">
      {imageLinks.map(c => {
        return <ImageLink key={c.id} data={c} />
      })}
    </section>
  )
}

export const query = graphql`
  fragment ImageLinkSectionContent on StrapiPageContent {
    imageLinks {
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageMobile {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      prefix
      header
      width
      linkText
      linkUrl
      textColor
      textColorMobile
      id
      addImageShadow
    }
  }
`
