import React from "react"

import "./icon-image-and-text.scss"

export default function IconImageAndTextItem(props) {
  const { header, icon_image, text } = props.data

  return (
    <div className="icon-image-and-text">
      <div className="icon-image">
        <img src={icon_image.publicURL} alt="icon" />
      </div>
      <div className="text">
        <h4>{header}</h4>
        <p>{text}</p>
      </div>
    </div>
  )
}
