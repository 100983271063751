import React from "react";
import { graphql } from "gatsby";
import { DynamicZone, Layout } from '@components';

export const query = graphql`
  query PageQuery($slug: String) {
    site {
      siteMetadata {
        siteURL
      }
    }
    strapiSeo {
      seo {
        description
        id
        shareImage {
          publicURL
        }
        title
      }
    }
    strapiPage(slug: { eq: $slug }) {
      strapiId
      title
      slug
      ...DynamicContent
      seo {
        description
        id
        shareImage {
          publicURL
        }
        title
      }
    }
  }
`;

const Page = ({ data }) => {
  return (
    <Layout 
      defaultSeo={data.strapiSeo.seo} 
      pageSeo={data.strapiPage.seo} 
      slug={data.strapiPage.slug} 
      siteURL={data.site.siteMetadata.siteURL} 
    >
        <DynamicZone content={data.strapiPage.content} />
    </Layout>
  );
};

export default Page;