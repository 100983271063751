import React from "react"
import { graphql } from "gatsby"

import {
  ImageHero,
  IconAndTextSection,
  IconImageAndTextSection,
  ImageLinkSection,
  ContactsSection,
  ImageAndTextSection,
  TextSingleColumn,
  TextTwoColumns,
  ImageSection,
  MapContainer,
} from "@components"

const DynamicZone = data => {
  return data.content.map((c, i) => {
    switch (c.component) {
      case "ContactsSection":
        return <ContactsSection key={i} data={c} />
      case "IconAndTextSection":
        return <IconAndTextSection key={i} data={c} />
      case "IconImageAndTextSection":
        return <IconImageAndTextSection key={i} data={c} />
      case "ImageAndTextSection":
        return <ImageAndTextSection key={i} data={c} />
      case "ImageHero":
        return <ImageHero key={i} data={c} />
      case "ImageLinkSection":
        return <ImageLinkSection key={i} data={c} />
      case "TextSingleColumn":
        return <TextSingleColumn key={i} data={c} />
      case "TextTwoColumns":
        return <TextTwoColumns key={i} data={c} />
      case "ImageSection":
        return <ImageSection key={i} data={c} />
      case "MapContainer":
        return <MapContainer key={i} data={c} />
      default:
        return null
    }
  })
}

export const query = graphql`
  fragment DynamicContent on StrapiPage {
    content {
      component
      ...HeroContent
      ...TextTwoColumnsContent
      ...TextSingleColumnContent
      ...IconAndTextSectionContent
      ...IconImageAndTextSectionContent
      ...ImageAndTextSectionContent
      ...ContactsSectionContent
      ...ImageLinkSectionContent
      ...ImageSectionContent
      id
    }
  }
`

export default DynamicZone
