import React from "react"
import "./navigation.scss"
import "./hamburger.scss"
import logo from "./y_logo_white.png"
import linkedinLogo from "./Linkedin.png"
import instagramLogo from "./Instagram.png"

import { graphql, useStaticQuery } from "gatsby"
import { bubble as Menu } from "react-burger-menu"

export default function Navigation(props) {
  const data = useStaticQuery(query)
  const { currentPage } = props

  const renderLinks = data => {
    return (
      <div className="links">
        <ul className="linklist">
          <li className="link-home">
            <a className={"link bold"} href={"/"}>
              home
            </a>
          </li>
          {data.strapiNavigation.links.map(link => {
            let activeClass = currentPage === link.uri ? " active" : ""

            return (
              <li key={link.id}>
                <a className={"link bold" + activeClass} href={"/" + link.uri}>
                  {link.text}
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return (
    <header>
      <div className={"navigation -mobile"}>
        <a className="logo" href="/">
          <img src={logo} alt="yalta logotype" />
        </a>
        <Menu width={"100%"} disableAutoFocus>
          {renderLinks(data)}
          <a
            className="linkedin-logo"
            href="https://se.linkedin.com/company/yalta-se"
          >
            <img src={linkedinLogo} alt="linkedin logotype" />
          </a>
          <a
            className="instagram-logo"
            href="https://www.instagram.com/lifeatyalta/"
          >
            <img src={instagramLogo} alt="instagram logotype" />
          </a>
        </Menu>
      </div>
      <div className="navigation">
        <a className="logo" href="/">
          <img src={logo} alt="yalta logotype" />
        </a>
        {renderLinks(data)}
      </div>
    </header>
  )
}

const query = graphql`
  query {
    strapiNavigation {
      links {
        id
        text
        uri
      }
    }
  }
`
