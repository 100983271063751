import React from "react"
import ReactMarkdown from "react-markdown"
import { graphql } from "gatsby"
import "./text.scss"

export default function TextSingleColumn(props) {
  const { backgroundColor, intro, text, textColor, elementId } = props.data

  const style = {
    backgroundColor: backgroundColor,
  }

  return (
    <section id={elementId} className="text-single-column" style={style}>
      <div className="center-col -narrow">
        <div className={"text -" + textColor}>
          {intro && <h2 className="centered single-column">{intro}</h2>}
          <ReactMarkdown source={text} />
        </div>
        <div className="extra"></div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment TextSingleColumnContent on StrapiPageContent {
    intro
    text
    backgroundColor
    textColor
    id
    elementId
  }
`
