import React from "react";
import { graphql } from 'gatsby';
import { Contact } from '@collection-types';
import './contacts.scss';

export default function ContactsSection(props) {
  const { contacts } = props.data;

  return (
    <section className="contacts-section">
        {contacts.map((c) => { return <Contact key={c.id} data={c} /> }) }
    </section>
  )
}

export const query = graphql`
  fragment ContactsSectionContent on StrapiPageContent {
    contacts {
        image {
            childImageSharp {
                fluid(maxWidth: 330) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        name
        title
        phone
        email
        id
    }
  }
`;