import React from "react"
import "@css/styles.scss"
import { Navigation, Footer } from "@single-types";
import { Helmet } from 'react-helmet';

const Layout = (props) => {

  const { defaultSeo, pageSeo, siteURL, slug } = props;
  
  const title = pageSeo?.title ? pageSeo.title : defaultSeo.title;
  const description = pageSeo?.description ? pageSeo.description : defaultSeo.description;
  const shareImage = pageSeo?.shareImage ? pageSeo.shareImage : defaultSeo.shareImage;
  const canonicalUrl = `${siteURL}/${slug}`;

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content={`${siteURL}${shareImage.publicURL}`} />

      </Helmet>
      <Navigation currentPage={slug} />
      {props.children}
      <Footer />
    </React.Fragment>
  )
}

export default Layout;