import React from "react"
import ReactMarkdown from "react-markdown"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Button } from "@components"
import "./text.scss"

export default function TextTwoColumns(props) {
  const {
    elementId,
    backgroundColor,
    intro,
    image,
    imageMobile,
    leftColumnText,
    linkText,
    linkUrl,
    rightColumnText,
    textColor,
  } = props.data

  const style = {
    backgroundColor: backgroundColor,
  }

  return (
    <section id={elementId} className="text-two-columns plopp" style={style}>
      <div className="center-col">
        <div className={"text -" + textColor}>
          <h2>{intro}</h2>
          <div className="columns">
            <div className="col">
              <ReactMarkdown source={leftColumnText} />
            </div>
            <div className="col">
              <ReactMarkdown source={rightColumnText} />
            </div>
          </div>
          {image && (
            <div className="image">
              <Img fluid={image.childImageSharp.fluid} />
            </div>
          )}
          {imageMobile && (
            <div className="image-mobile">
              <Img fluid={imageMobile.childImageSharp.fluid} />
            </div>
          )}
          {linkText && linkUrl && (
            <div className="link-button">
              <Button text={linkText} url={linkUrl} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment TextTwoColumnsContent on StrapiPageContent {
    image {
      childImageSharp {
        fluid(maxWidth: 660) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageMobile {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    intro
    linkText
    linkUrl
    leftColumnText
    rightColumnText
    backgroundColor
    textColor
    elementId
  }
`
